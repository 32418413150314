import React from "react";
// Customizable Area Start
import SchedulingController, {
    Props,
} from "./SchedulingController.web";
import { Button, Grid, Typography, styled } from "@material-ui/core";
import HomeLayout from "../../../components/src/HomeLayout";
import SchedulingCalendar from "../../../components/src/SchedulingCalendar";
import { calendar, clock, contact, schedule_success, time } from "./assets";
import { ToastContainer } from "react-toastify";
// Customizable Area End


// Customizable Area Start
const PageStyle = styled("div")({
    "& *": {
        fontFamily: "Urbanist, sans-serif",
    },

    marginTop: "30px",
    paddingInline: "100px",
    paddingBottom: "20px",

    "@media (max-width: 960px)": {
        marginTop: "30px",
        paddingInline: '50px',
    },

    "@media (max-width: 600px)": {
        marginTop: "30px",
        paddingInline: '30px',
    },
    "& .gridContainer": {
        borderRadius: "8px",
        border: "1px solid #E8ECF4",
        maxHeight: "460px",
        overflowY: "auto",
        marginRight: "10px",
        flex: 1,
        display: "flex",
        flexDirection: "column",

        "@media (max-width: 960px)": {
            marginRight: "0px",
            marginTop: "10px"
        },
    },
    "& .topContainer": {
        display: "flex",
        flexDirection: "row",
        marginBottom: "30px",
        flexWrap: "wrap",
        gap: "20px"
    },
    "& .itemContainer": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
        minWidth: "300px",
    },
    "& .successItem": {
        border: "1px solid #E8ECF4",
        borderRadius: "8px",
        backgroundColor: "#F7F8F9",
        margin: "6px",
        padding: "15px",
        minWidth: "210px",
        width: "fit-content",

        "@media(max-width:600px)": {
            minWidth: "unset",
            width: "unset",
            display: "flex"
        } 
    },
    "& .timeSlot": {
        border: "1px solid #E8ECF4",
        height: "56px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#F7F8F9"
    },
    "& .timeSlotSelected": {
        border: "1px solid #2F7735",
        height: "56px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#E6EEE6"
    },
    '& .cancelBtn': {
        border: '1px solid #2F7735',
        borderRadius: '8px',
        fontSize: "15px",
        width: "150px",
        height: '56px',
        color: '#2F7735',
        fontWeight: 600,
        fontFamily: "Urbanist, sans-serif",
        marginRight: "20px",
        textTransform: "none",

        "@media(max-width:450px)": {
            width: "120px",
            height: '45px',
        }
    },
    '& .submitBtn': {
        height: '56px',
        width: "150px",
        background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
        color: '#FFFFFF',
        fontSize: "15px",
        borderRadius: '8px',
        marginLeft: '1158 px',
        fontWeight: 600,
        textTransform: "none",

        "@media(max-width:450px)": {
            width: "120px",
            height: '45px',
        }
    },
    "& .successRow": {
        "@media(max-width:600px)": {
            flexDirection: "column",
            width: "100%"
        } 
    }
})
// Customizable Area End


export default class Scheduling extends SchedulingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <HomeLayout profile={this.state.profile} navigation={this.props.navigation}>
                <PageStyle>
                    {this.state.step === 1 &&
                        <>
                            <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 700, fontSize: "24px", padding: '10px 0 20px 0' }}>Booking</Typography>
                            <div className="topContainer">
                                <div className="itemContainer">
                                    <img src={contact} style={{ width: "25px", height: "auto" }} />
                                    <div>
                                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 600, fontSize: "14px", color: "#8391A1" }}>Instructor</Typography>
                                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 600, fontSize: "16px", color: "#333333" }}>{`${this.state.coachProfile?.attributes?.first_name} ${this.state.coachProfile?.attributes?.last_name}`}</Typography>
                                    </div>
                                </div>
                                <div className="itemContainer">
                                    <img src={clock} style={{ width: "25px", height: "auto" }} />
                                    <div>
                                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 600, fontSize: "14px", color: "#8391A1" }}>Duration</Typography>
                                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 600, fontSize: "16px", color: "#333333" }}>30 mins</Typography>
                                    </div>
                                </div>
                                <div className="itemContainer">
                                    <img src={calendar} style={{ width: "25px", height: "auto" }} />
                                    <div>
                                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 600, fontSize: "14px", color: "#8391A1" }}>Date</Typography>
                                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 600, fontSize: "16px", color: "#333333" }}>{this.state.selectedDate?.format("ddd, DD MMM YYYY")}</Typography>
                                    </div>
                                </div>
                            </div>
                            <Grid container spacing={2}>
                                <Grid xs={12} sm={12} md={6} style={{ display: "flex", maxHeight: "400px" }}>
                                    <SchedulingCalendar availability={this.state.availability} navigation={this.props.navigation} onChangeDate={this.onChangeDate} />
                                </Grid>
                                <Grid xs={12} sm={12} md={6} style={{ display: "flex", maxHeight: "400px" }}>
                                    <div className="gridContainer">
                                        <div style={{ paddingInline: "20px", paddingTop: "20px", paddingBottom: "10px" }}>
                                            <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 700, fontSize: "16px", color: "#333333" }}>Select a Time</Typography>
                                        </div>
                                        <Grid container style={{ padding: "15px", paddingTop: 0 }}>
                                            {this.state.selectedDate?.format('YYYY-MM-DD') && this.sortArrayByTime(this.filterPastTimes(this.state.availability.find((avail: { availability_date: string }) => avail.availability_date === this.state.selectedDate?.format('YYYY-MM-DD'))?.time_slots,this.state.selectedDate?.format('YYYY-MM-DD')))?.map((time: string) =>
                                                <Grid item xs={6} sm={4} style={{ padding: "5px" }}>
                                                    <div data-test-id="time-slot" className={time === this.state.selectedTime ? "timeSlotSelected" : "timeSlot"} style={{ cursor: "pointer" }} onClick={this.onSelectTime(time)}>
                                                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 500, fontSize: "15px", color: time === this.state.selectedTime ? "#2F7735" : "#8391A1", }}>{time}</Typography>
                                                    </div>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: "30px" }}>
                                <div>
                                    <Button data-test-id='cancelBtn' className="cancelBtn" onClick={() => this.props.navigation.goBack()}>Cancel</Button>
                                </div>
                                <div>
                                    <Button disabled={!this.state.selectedTime} style={{ opacity: this.state.selectedTime ? 1 : 0.6 }} data-test-id='submitBtn' className="submitBtn" onClick={this.onNext}>Next</Button>
                                </div>
                            </div>
                        </>
                    }
                    {this.state.step === 2 &&
                        <div style={{ display: "flex", alignItems: 'center', flexDirection: "column" }}>
                            <img src={schedule_success} style={{ width: "119px", height: "auto", marginTop: "20px", marginBottom: "10px" }} />
                            <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 700, fontSize: "24px", color: "#1E232C" }}>Schedule Lesson</Typography>
                            <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 500, fontSize: "14px", color: "#8391A1", marginBottom: "15px" }}>Below are the specifics of your scheduled lesson.</Typography>
                            <div style={{ display: "flex" }} className="successRow">
                                <div className="itemContainer successItem">
                                    <img src={contact} style={{ width: "25px", height: "auto" }} />
                                    <div>
                                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 600, fontSize: "14px", color: "#8391A1" }}>Instructor</Typography>
                                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 600, fontSize: "16px", color: "#333333", maxWidth: "175px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{`${this.state.coachProfile?.attributes?.first_name} ${this.state.coachProfile?.attributes?.last_name}`}</Typography>
                                    </div>
                                </div>
                                <div className="itemContainer successItem" >
                                    <img src={clock} style={{ width: "25px", height: "auto" }} />
                                    <div>
                                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 600, fontSize: "14px", color: "#8391A1" }}>Duration</Typography>
                                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 600, fontSize: "16px", color: "#333333" }}>30 mins</Typography>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex" }} className="successRow">

                                <div className="itemContainer successItem" >
                                    <img src={calendar} style={{ width: "25px", height: "auto" }} />
                                    <div>
                                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 600, fontSize: "14px", color: "#8391A1" }}>Date</Typography>
                                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 600, fontSize: "16px", color: "#333333" }}>{this.state.selectedDate?.format("ddd, DD MMM YYYY")}</Typography>
                                    </div>
                                </div>
                                <div className="itemContainer successItem">
                                    <img src={time} style={{ width: "25px", height: "auto" }} />
                                    <div>
                                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 600, fontSize: "14px", color: "#8391A1" }}>Time</Typography>
                                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 600, fontSize: "16px", color: "#333333" }}>{this.textRangeGenerator(this.state.selectedTime)}</Typography>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "20px" }}>
                                <div>
                                    <Button data-test-id='cancelBtn' className="cancelBtn" onClick={() => this.setState({ step: 1 })}>Cancel</Button>
                                </div>
                                <div>
                                    <Button disabled={this.state.isLoading} data-test-id='submitBtn' className="submitBtn" onClick={this.onConfirm}>Confirm</Button>
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.step === 3 &&
                        <div style={{ display: "flex", alignItems: 'center', flexDirection: "column" }}>
                            <img src={schedule_success} style={{ width: "119px", height: "auto", marginTop: "20px", marginBottom: "10px" }} />
                            <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 700, fontSize: "24px", color: "#1E232C" }}>Booking Confirmed!</Typography>
                            <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 500, fontSize: "14px", color: "#8391A1", marginBottom: "15px" }}>Your Booking has been Confirmed successfully.</Typography>

                            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "15px" }}>
                                <div>
                                    <Button data-test-id='submitBtn' className="submitBtn" onClick={this.onGoToBooking}>Go to Booking</Button>
                                </div>
                            </div>
                        </div>
                    }
                    <ToastContainer />
                </PageStyle>
            </HomeLayout>
            // Customizable Area End
        )
    }
}
