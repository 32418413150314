import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { sendAPIRequest } from "../../../components/src/utils";
import { toast } from 'react-toastify';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  firstName: string,
  lastName: string,
  dateofBirth: Date | null,
  email: string,
  country: string,
  state: any,
  city: string,
  role_id: number,
  password: string,
  handicapScore: boolean,
  handicapScoreValue: string,
  termsAndConditions: boolean,
  accountType: boolean,
  showPassword: boolean,
  stateList: any[],
  firstNameError: string,
  lastNameError: string,
  dateofBirthError: string,
  emailError: string,
  passwordError: string,
  countryError: string,
  stateError: string,
  cityError: string,
  handicapScoreError: string,
  termsAndConditionsError: string,
  clubAffiliation: string,
  flatpickrInstance: any;
  isOpen: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  handleUnactivateAccount(responseJson: any) {
    // throw new Error("Method not implemented.");
  }
  hanldeNavigationPath(data: any, isTeacherPage: any): any {
    // throw new Error("Method not implemented.");
  }
  // Customizable Area Start
  // currentCountryCode: any;
  signupFormSubmitCallId: any;
  // countrylistCallId: any;
  statelistCallId: any;
  // resendEmailCallId: any;
  apiGetCountryCallId: any;
  apiGetStateCallId: any;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.signupFormSubmitCallId = null;
    this.statelistCallId = null;

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      dateofBirth: null,
      email: "",
      country: "",
      state: "",
      city: "",
      role_id: 0,
      password: "",
      handicapScore: false,
      handicapScoreValue: "",
      termsAndConditions: false,
      accountType: false,
      showPassword: false,
      stateList: [],
      firstNameError: "",
      lastNameError: "",
      dateofBirthError: "",
      emailError: "",
      passwordError: "",
      countryError: "",
      stateError: "",
      cityError: "",
      handicapScoreError: "",
      termsAndConditionsError: "",
      clubAffiliation: "",
      flatpickrInstance: null,
      isOpen: false
      // Customizable Area End
    };

    // Customizable Area Start
    localStorage.setItem('role_id', String(0));
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (responseJson.errors) {
        toast.error(responseJson.errors[0]?.message || responseJson.errors[0])
        return;
      }

      if (apiRequestCallId === this.signupFormSubmitCallId) {
        this.handleSignupFormSubmit(responseJson, errorReponse);
      }
      if (apiRequestCallId === this.statelistCallId) {
        this.handleStateResponse(responseJson, errorReponse)

      }

    }
    // Customizable Area End
  }

  // Customizable Area Start



  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevState.country !== this.state.country) {

      this.fetchStates(this.state.country)
    }
  }

  fetchStates = async (country_code: string) => {

    try {
      this.statelistCallId = sendAPIRequest(
        `${configJSON.state_listApiEndPoint}?country_code=${country_code}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

    } catch (error) {

    }
  };

  handleClick(event: any) {
    if (event.target?.value) {
      this.setState({
        accountType: false
      });
    }
  }

  onClickOutside = () => {
    this.setState({ isOpen: false });
  }

  onCalendarChange = (date: Date) => {
    this.setState({ dateofBirth: date })
  }

  handleChange = (event: any) => {
    const roleId = Number(event?.target?.value);
    this.setState({
      role_id: roleId
    });
    localStorage.setItem('role_id', roleId.toString());
  };

  handleFirstNameChange(e: any) {
    this.setState({ firstName: e?.target?.value })
  }

  handleLastNameChange(e: any) {
    this.setState({ lastName: e?.target?.value })
  }

  handleDateOfBirthChange(e: any) {
    this.setState({ dateofBirth: e?.target?.value })
  }

  handleEmailChange(e: any) {
    this.setState({ email: e?.target?.value })
  }
  handleCalendarIconClick = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  };
  handlePasswordChange(e: any) {
    this.setState({ password: e?.target?.value })
  }

  handleStateChange(item: any) {
    this.setState({ state: item })
  }

  handleHandicapChange(e: any) {
    this.setState({ handicapScore: e?.target?.checked })
  }

  handleCityChange(e: any) {
    this.setState({ city: e.target.value })
  }

  handleTermChange(e: any) {
    this.setState({ termsAndConditions: e.target.checked })
  }

  handleClubAffiliationChange(e: any) {
    this.setState({ clubAffiliation: e.target.value })
  }

  handleHandicapScore(e: any) {
    this.setState({ handicapScoreValue: e.target.value })
  }

  handleCountryChange(value: any) {
    this.setState({ country: value })
  }

  togglePasswordVisibility = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };
  handleSignupFormSubmit = (responseJson: any, errorReponse: any) => {
    if (!responseJson?.message) {
      this.handleStorageData(responseJson)
      toast.success('Your Account has been Successfully Created');
      this.props.navigation.navigate("CustomForm");
    } else {
      this.handleUnactivateAccount(responseJson)
    }
    this.parseApiCatchErrorResponse(errorReponse);

  }

  handleSubmit = () => {
    const firstNameError = this.validateFirstName(this.state.firstName);
    const lastNameError = this.validateLasttName(this.state.lastName);
    const dateofBirthError = this.validateDateofBirth(this.state.dateofBirth);
    const emailError = this.validateEmail(this.state.email);
    const passwordError = this.validatePassword(this.state.password);
    const countryError = this.validateCountry(this.state.country);
    const stateError = this.validateState(this.state.state);
    const cityError = this.validateCity(this.state.city);
    const handicapScoreError = this.state.handicapScore ? this.validateHandicapScore(this.state.handicapScoreValue) : '';
    const termsAndConditionsError = this.validateTermsAndConditions(this.state.termsAndConditions);

    this.setState({
      firstNameError: "",
      lastNameError: "",
      dateofBirthError: "",
      emailError: "",
      passwordError: "",
      countryError: "",
      stateError: "",
      cityError: "",
      handicapScoreError: "",
      termsAndConditionsError: "",
    });
    // Update state with errors
    this.setState({
      firstNameError,
      lastNameError,
      dateofBirthError,
      emailError,
      passwordError,
      countryError,
      stateError,
      cityError,
      handicapScoreError,
      termsAndConditionsError,
    });
    if (!firstNameError && !cityError && !lastNameError && !dateofBirthError && !emailError && !passwordError && !countryError && !stateError && !termsAndConditionsError) {
      this.handleAPICall();
    }
  }

  //handleAPI Call
  handleAPICall = () => {
    if (this.state.role_id === 0) {
      const body = {
        "data": {
          "type": "email_account",
          "attributes": {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            date_of_birth: this.state.dateofBirth,
            country: this.state.country,
            state: this.state.state.value,
            city: this.state.city,
            role_id: this.state.role_id,
            handicap_selected: this.state.handicapScore,
            ...(this.state.handicapScore && { handicap_score: this.state.handicapScoreValue }),
            club_affiliation: "",
            password: this.state.password,
            terms_and_conditions: this.state.termsAndConditions,
          }
        }
      }

      this.signupFormSubmitCallId = sendAPIRequest(configJSON.signupApiEndPoint, {
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json' },
      });
    }
    else {
      const body = {
        "data": {
          "type": "email_account",
          "attributes": {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            date_of_birth: this.state.dateofBirth,
            country: this.state.country,
            state: this.state.state.value,
            city: this.state.city,
            role_id: this.state.role_id,
            club_affiliation: this.state.clubAffiliation,
            password: this.state.password,
            terms_and_conditions: this.state.termsAndConditions,
          }
        }

      }
      this.signupFormSubmitCallId = sendAPIRequest(configJSON.signupApiInstructorEndPoint, {
        method: 'POST',
        body: body,
        headers: { 'Content-Type': 'application/json' },
      });
    }
  }

  stateCodesToDropDown = (data: any) => {
    if (typeof data === 'object') {
      data = Object.keys(data).sort().map(key => data[key]).filter(Boolean);
    }
    
    return (
      data.map((item: any) => ({
        label: item,
        value: item,
      }))
    )
  };
  cancelButtonHandler = () => {
    this.props.navigation.navigate("EmailAccountLogin");

  }

  handleStorageData = (responseJson: any) => {
    localStorage.setItem('user', JSON.stringify(responseJson?.data));
    localStorage.setItem('authToken', JSON.stringify(responseJson?.meta?.token));
    // this.props.navigation.navigate("EmailAccountLogin")
  }

  termsConditionHandler = (e: any) => {
    this.props.navigation.navigate("TermsConditionsDetail");
  }

  handleStateResponse = (responseJson: any, apiRequestCallId: string) => {

    const dropdownOptions = this.stateCodesToDropDown(responseJson);
    this.setState({
      stateList: dropdownOptions,
    });
  }
  validateFirstName = (firstName: string) => {
    if (!firstName?.trim()) {
      return ("First name can't be blank")
    } else if (!/^[a-zA-Z\s]*$/.test(firstName)) {
      return ("First name cannot contain numbers or special characters");
    }
    return "";
  }
  validateLasttName = (lastName: string) => {
    if (!lastName?.trim()) {
      return ("Last name can't be blank")
    } else if (!/^[a-zA-Z\s]*$/.test(lastName)) {
      return ("Last name cannot contain numbers or special characters");
    }
    return "";
  }
  validateDateofBirth = (dateofBirth: Date | null) => {
    if (!dateofBirth) {
      return ("Date of birth can't be blank")
    }
    return "";
  }
  validateEmail = (email: string) => {
    if (!email?.trim()) {
      return ("Email can't be blank")
    } else if (!/^[a-z0-9._-]+@[a-z._-]+\.[a-z]{2,3}$/.test(email)) {
      return ("Invalid Email Address")
    }
    return "";
  }
  validatePassword = (password: string) => {
    if (!password?.trim()) {
      return ("Password can't be blank")
    } else if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/.test(password)) {
      return "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be between 8-16 characters";
    }
    return "";
  }
  validateCountry = (country: string) => {
    if (!country?.trim()) {
      return ("Country can't be blank")
    }
    return "";
  }
  validateState = (state: any) => {
    if (!state?.value?.trim()) {
      return ("State can't be blank")
    }
    return "";
  }
  validateCity = (city: string) => {
    if (!city?.trim()) {
      return ("City can't be blank")
    } else if (!/^[a-zA-Z\s]*$/.test(city)) {
      return ("City cannot contain numbers or special characters");
    }
    return "";
  }

  validateHandicapScore = (handicapScoreValue: string) => {
    if (!handicapScoreValue) {
      return "Handicap Score can't be blank";
    } else if (isNaN(Number(handicapScoreValue)) || !Number.isInteger(Number(handicapScoreValue))) {
      return "Handicap score must be an integer";
    } else if (Number(handicapScoreValue) < 5 || Number(handicapScoreValue) > 25) {
      return "Handicap score must be between +5 and +25";
    }
    return "";
  }

  validateTermsAndConditions = (TermsAndConditions: boolean) => {
    if (!TermsAndConditions) {
      return "Terms and conditions must be accepted"
    }
    return "";
  }
  // Customizable Area End
}