import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import ReviewsController, {
  Props,
} from "./ReviewsController.web";
import { styled } from "@material-ui/core";
import HomeLayout from "../../../components/src/HomeLayout";
import { grayStar, orangeStar } from "./assets";
import { defaultAvatar } from "../../../blocks/user-profile-basic/src/assets";
// Customizable Area End


// Customizable Area Start

const PageStyle = styled("div")({
  marginTop: "30px",
  paddingInline: "100px",

  "& *": {
    fontFamily: "Urbanist,sans-serif",
  },

  "@media (max-width: 960px)": {
    marginTop: "30px",
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    marginTop: "30px",
    paddingInline: '30px',
  },

  "& .textField": {
    backgroundColor: "#F7F8F9",
    borderRadius: "8px",
    border: "1px solid #E8ECF4",
    maxWidth: "345px",
    marginBottom: "2px",
    height: "56px",
    padding: "15px",
    width: "100%",

    "@media (max-width: 600px)": {
      maxWidth: "unset"
    },
  },
  "& .userPhoto": {
    height: "96px",
    width: "96px",
    objectFit: "cover",
    borderRadius: "50%"
  },
  "& .userTextContainer": {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  "& .userTitle": {
    textOverflow: "ellipsis",
    fontSize: "24px",
    whiteSpace: "nowrap",
    maxWidth: "250px",
    overflow: "hidden",
    fontWeight: 600,

    "@media(max-width:600px)": {
      maxWidth: "180px",
    },

    "@media(max-width:400px)": {
      maxWidth: "150px",
    }
  },
  "& .userSubtitle": {
    fontSize: "15px",
    fontWeight: 500,
    color: "#8391A1",

    "@media(max-width:600px)": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "120px",
    },

    "@media(max-width:400px)": {
      maxWidth: "100px",
    }
  },

  "& .userReviewTitle": {
    fontSize: "14px",
    color: "#333333",
    fontWeight: 600,
  },

  "& .topContainer": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "30px",
    marginTop: "20px",

    "@media(max-width:700px)": {
        flexDirection: "column-reverse",
        alignItems: "flex-start",
        gap: "20px",
      },
  },
})
// Customizable Area End


export default class Reviews extends ReviewsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    function StarRating({ rating, size = "38px" }: { rating: number, size?: string }) {
        const roundedRating = Math.round(rating);
        const fullStars = roundedRating;
        const emptyStars = 5 - fullStars;
        const stars = [];
      
        for (let i = 0; i < fullStars; i++) {
          stars.push(<img key={i} src={orangeStar} style={{width: size, height: size}} alt="orange-star" />);
        }
      
        for (let i = 0; i < emptyStars; i++) {
          stars.push(<img key={fullStars + i} src={grayStar} style={{width: size, height: size}} alt="gray-star" />);
        }
      
        return <div>{stars}</div>;
      }
      this.starGenerateFunction = StarRating;
    // Customizable Area End
    return (
      // Customizable Area Start
      <HomeLayout profile={this.state.profile} currentPath="Reviews" onChangeProfile={this.onChangeProfile} navigation={this.props.navigation}>
        <PageStyle>
          <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "24px", fontWeight: 700, padding: '10px 0 0 0' }}>Rating & Reviews</Typography>
                <div className="topContainer">
                    <div>
                        <Typography variant="body1" style={{ fontFamily: "Urbanist,sans-serif", fontSize: "32px", fontWeight: 700, color: "#333333", display: "inline" }}>{this.state.coachProfile?.attributes?.average_rating?.toFixed(1)}</Typography>
                        <Typography variant="body1" style={{ fontFamily: "Urbanist,sans-serif", fontSize: "15px", fontWeight: 500, color: "#8391A1", display: "inline", marginLeft: "10px" }}>{`(${this.state.coachReviews?.length} Reviews)`}</Typography>
                        <StarRating rating={this.state.coachProfile?.attributes?.average_rating} />
                    </div>
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                            <img className="userPhoto" src={this.state.coachProfile?.attributes?.photo ?? defaultAvatar} />
                            <div className="userTextContainer">
                                <Typography variant="h3" className="userTitle">
                                    {`${this.state.coachProfile?.attributes?.first_name} ${this.state.coachProfile?.attributes?.last_name}`}
                                </Typography>
                                <Typography variant="h4" className="userSubtitle">{this.state.coachProfile?.attributes?.handicap}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <Typography variant="h2" style={{ fontFamily: "Urbanist,sans-serif", fontSize: "16px", fontWeight: 700, color: "#333333", marginTop: "30px" }}>Reviews</Typography>
                <div style={{display: "flex", gap: "25px", flexDirection:"column", paddingTop: "25px"}}>
                    {this.state.coachReviews.map((review: any) => 
                    <div key={review.id} style={{display: "flex", flexDirection: "row", gap: "10%", borderBottom: "1px solid #9999994D", paddingBottom: "25px"}}>
                           <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                            <img className="userPhoto" style={{width: "48px", height: "48px"}} src={review?.attributes?.reviewer_photo ?? defaultAvatar} />
                            <div className="userTextContainer" style={{gap: "3px"}}>
                                <Typography variant="h3" className="userReviewTitle">
                                    {`${review?.attributes?.reviewer_full_name}`}
                                </Typography>
                                <StarRating size="14px" rating={review?.attributes?.rating} />
                                <Typography variant="h3" style={{fontSize: "11px", color: "#8391A1", fontWeight: 500}}>
                                    {`${review?.attributes?.created_at}`}
                                </Typography>
                            </div>
                        </div>
                        <div>
                            <Typography variant="body1" style={{fontWeight: 500, fontSize: "14px", color: "#333333"}}>
                                    {`${review?.attributes?.feedback}`}
                            </Typography>
                        </div>

                    </div>
                    )}
                </div>
        </PageStyle>
      </HomeLayout>
      // Customizable Area End
    )
  }
}
