import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import CoachProfileController, {
  Props,
} from "./CoachProfileController.web";
import { Button, Grid, styled } from "@material-ui/core";
import HomeLayout from "../../../components/src/HomeLayout";
import { defaultAvatar, icCall, icPayment, icSub } from "./assets";
import { grayStar, orangeStar } from "../../../blocks/inventorymanagement/src/assets";
// Customizable Area End


// Customizable Area Start

const PageStyle = styled("div")({
  "& *": {
    fontFamily: "Urbanist, sans-serif",
  },

  marginTop: "30px",
  marginBottom: "20px",
  paddingInline: "100px",

  "@media (max-width: 960px)": {
    marginTop: "30px",
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    marginTop: "30px",
    paddingInline: '30px',
  },

  "& .userPhoto": {
    width: "96px",
    height: "96px",
    borderRadius: "50%",
    objectFit: "cover"
  },
  "& .userTextContainer": {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  "& .userTitle": {
    fontWeight: 600,
    fontSize: "24px",
    maxWidth: "250px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",

    "@media(max-width:600px)": {
      maxWidth: "120px",
    },

    "@media(max-width:400px)": {
      maxWidth: "100px",
    }
  },
  "& .userSubtitle": {
    fontSize: "15px",
    color: "#8391A1",
    fontWeight: 500,

    "@media(max-width:600px)": {
      maxWidth: "120px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },

    "@media(max-width:400px)": {
      maxWidth: "100px",
    }
  },
  "& .buyNowBtn": {
    height: '40px',
    width: "112px",
    background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
    color: '#FFFFFF',
    fontSize: "15px",
    borderRadius: '6px',
    fontWeight: 600,
    textTransform: "unset",

    "@media(max-width: 860px)": {
      width: "100px",
      height: '35px',
    },

    "@media(max-width: 400px)": {
      width: "65px",
      height: "56px"
    },
  },
  "& .sideBox": {
    display: "flex",
    flexDirection: "row",
    padding: "20px",
    backgroundColor: "#F7F8F9",
    borderRadius: "8px",
    border: "1px solid #E8ECF4",
    gap: "15px"
  },
  "& .boxTitle": {
    fontSize: "15px",
    color: "#8391A1",
  },
  "& .boxSubtitle": {
    fontSize: "24px",
    fontWeight: 600,
    color: "#333333"
  },

  "& .introductionContainer": {
    backgroundColor: "#F7F8F9",
    borderRadius: "8px",
    border: "1px solid #E8ECF4",
    padding: "15px",
    marginTop: "20px",

    "@media(max-width:1280px)": {
      marginTop: "18px"
    }
  },

  "& .instructionTitle": {
    fontWeight: 700,
    fontSize: "16px",
    color: "#333333"
  },

  "& .introductionText": {
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "20px",
    marginTop: "10px",
  },
  "& .middleBox": {
    paddingInline: "12px",

    "@media(max-width:600px)": {
      paddingBlock: "15px",
      paddingInline: 0,
    }
  }
})
// Customizable Area End


export default class CoachProfile extends CoachProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <HomeLayout profile={this.state.profile} navigation={this.props.navigation}>
        <PageStyle>
          <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "24px", fontWeight: "700", padding: '10px 0 20px 0' }}>Coach Profile</Typography>
          <Grid container spacing={2}>
            <Grid xs={12} lg={6} item>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'center' }}>
                <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                  <img className="userPhoto" src={this.state.coachProfile?.attributes?.photo ?? defaultAvatar} />
                  <div className="userTextContainer">
                    <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                    <Typography variant="h3" className="userTitle">
                      {`${this.state.coachProfile?.attributes?.first_name ?? ""} ${this.state.coachProfile?.attributes?.last_name ?? ""}`}
                    </Typography>
                    <img onClick={this.onFavourite} style={{width: "35px", height: "35px", cursor: "pointer"}} src={this.state.isFavourite ? orangeStar : grayStar} />
                    </div>
                    <Typography variant="h4" className="userSubtitle">{this.state.coachProfile?.attributes?.handicap}</Typography>
                  </div>
                </div>
                <Button data-test-id='buy-now-btn' className="buyNowBtn" onClick={this.onBuyNow}>Buy Now</Button>
              </div>
              <div className="introductionContainer" style={{ marginTop: "33px" }}>
                <Typography variant="h3" className="instructionTitle">Introduction</Typography>
                {this.state.coachProfile?.attributes?.profile_video &&
                  <video width="100%"
                    style={{ borderRadius: "8px", marginTop: "10px" }}
                    controls
                  >
                    <source src={this.state.coachProfile?.attributes?.profile_video}
                      type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>}
                <Typography variant="body1" className="introductionText">{this.state.coachProfile?.attributes?.describe_your_self}</Typography>
              </div>
            </Grid>
            <Grid xs={12} lg={6} item spacing={2}>
              <Grid xs={12} container>
                <Grid xs={12} sm={4} xl={4} item>
                  <div className="sideBox">
                    <div style={{ width: "64px", height: "64px", borderRadius: "50%", display: "flex", alignItems: 'center', justifyContent: "center", border: "1px solid #2F7735" }}>
                      <img style={{ width: "26px" }} src={icSub} />
                    </div>
                    <div style={{ flexDirection: "column", display: "flex", }}>
                      <Typography variant="body1" className="boxTitle">Lesson</Typography>
                      <Typography variant="body1" className="boxSubtitle">{this.state.coachProfile?.attributes?.lesson}</Typography>
                    </div>
                  </div>
                </Grid>
                <Grid xs={12} sm={4} xl={4} item className="middleBox">
                  <div className="sideBox" style={{cursor: "pointer"}} onClick={() => this.props.navigation.navigate("Reviews", {id: this.state.coachProfile.attributes.account_id})}>
                    <div style={{ width: "64px", height: "64px", borderRadius: "50%", display: "flex", alignItems: 'center', justifyContent: "center", border: "1px solid #2F7735" }}>
                      <img style={{ width: "26px" }} src={icCall} />
                    </div>
                    <div style={{ flexDirection: "column", display: "flex", }}>
                      <Typography variant="body1" className="boxTitle">Rating</Typography>
                      <Typography variant="body1" className="boxSubtitle">{Number(this.state.coachProfile?.attributes?.average_rating).toFixed(1)}</Typography>
                    </div>
                  </div>
                </Grid>
                <Grid xs={12} sm={4} xl={4} item>
                  <div className="sideBox">
                    <div style={{ width: "64px", height: "64px", borderRadius: "50%", display: "flex", alignItems: 'center', justifyContent: "center", border: "1px solid #2F7735" }}>
                      <img style={{ width: "26px" }} src={icPayment} />
                    </div>
                    <div style={{ flexDirection: "column", display: "flex", }}>
                      <Typography variant="body1" className="boxTitle">Price</Typography>
                      <Typography variant="body1" className="boxSubtitle">{this.state.coachProfile?.attributes?.price}</Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className="introductionContainer" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Typography variant="h3" className="instructionTitle" style={{ width: "100%" }}>Videos</Typography>

                <Grid xs={12} container spacing={2} style={{ marginTop: "10px" }}>
                  {this.state.coachProfile?.attributes?.videos?.map((video: { title: string, url: string }, index: number) => (
                    <Grid item xs={6} style={{ paddingLeft: index % 2 === 0 ? 0 : '10px', paddingRight: index % 2 !== 0 ? 0 : '10px' }}>
                      <video width="100%"
                        style={{ borderRadius: "8px" }}
                        controls
                      >
                        <source src={video.url}
                          type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <Typography variant="h3" className="instructionTitle" style={{ fontWeight: 600, marginTop: "5px" }}>{video.title}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </PageStyle>
      </HomeLayout>
      // Customizable Area End
    )
  }
}
