import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;

}

interface S {
    favoriteInstructors?: any[];
    newInstructors?: any[];
    findInstructors?: any[];
    profile: any;
    speciality?: string;
    searchText: string;
    isFilterVisible: boolean;
    location: string;
    price: number;
    rate?: number;
    newInstructorSearchText: string;
    favoriteInstructorSearchText: string;
    isFavoriteFilterOpen: boolean;
    isNewFilterOpen: boolean;
}

interface SS {
    id: any;
}

export default class HomepageController extends BlockComponent<
    Props,
    S,
    SS
> {

    apiGetFavoriteInstructorCallId: string = "";
    apiGetNewInstructorCallId: string = "";
    apiGetFindInstructorCallId: string = "";
    apiGetProfileCallId: string = "";
    searchDebounceTimeout: any = null;
    testStarRating: any = null;
    testNewInstructor: any = null;
    testFavInstructor: any = null;
    
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            favoriteInstructors: undefined,
            newInstructors: undefined,
            findInstructors: undefined,
            profile: null,
            speciality: "putting specialist",
            searchText: "",
            isFilterVisible: false,
            location: "",
            price: 0,
            rate: undefined,
            newInstructorSearchText: "",
            favoriteInstructorSearchText: "",
            isFavoriteFilterOpen: false,
            isNewFilterOpen: false,
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        try {
            const token = await getStorageData('authToken');
            if (token) {
                this.getNewInstructors(token);
                this.getFavoriteInstructors(token);
                this.getFindInstructors(token, this.state.speciality);
                this.getProfile(token);
                this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
            } else {
                this.props.navigation.navigate("EmailAccountLogin");
            }
        } catch { }
    }

    async componentDidUpdate(prevProps: any, prevState: any) {
        const token = await getStorageData('authToken');

        if (this.state.speciality !== prevState.speciality) {
            this.getFindInstructors(token, this.state.speciality);
        }
        if (prevState.searchText !== this.state.searchText) {
            this.handleSearchTextChange();
        }
    }

    handleSearchTextChange = () => {
        if (this.searchDebounceTimeout) {
            clearTimeout(this.searchDebounceTimeout);
          }
    
          this.searchDebounceTimeout = setTimeout(async () => {
            const token = await getStorageData('authToken');
            if (token) {
                this.getFindInstructors(token, this.state.speciality);
                this.getNewInstructors(token);
                this.getFavoriteInstructors(token);
            }
          }, 500);
    }

    async receive(from: string, message: Message) {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            this.handleAPIResponseMessage(message);
        }
    }

    handleAPIResponseMessage(message: Message) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        if (apiRequestCallId === this.apiGetFavoriteInstructorCallId) {
            this.handleGetFavoriteInstructorCallId(responseJson, errorResponse);
        }
        if (apiRequestCallId === this.apiGetNewInstructorCallId) {
            this.handleGetNewInstructorCallId(responseJson, errorResponse);
        }
        if (apiRequestCallId === this.apiGetFindInstructorCallId) {
            this.handleGetFindInstructorCallId(responseJson, errorResponse);
        }
        if (apiRequestCallId === this.apiGetProfileCallId) {
            this.handleGetProfileCallId(responseJson, errorResponse);
        }
    }

    handleGetFavoriteInstructorCallId(responseJson: any, errorResponse: any) {
        if (responseJson && responseJson.data) {
            this.setState({ favoriteInstructors: responseJson.data })
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    handleGetNewInstructorCallId(responseJson: any, errorResponse: any) {
        if (responseJson && responseJson.data) {
            this.setState({ newInstructors: responseJson.data })
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    handleGetFindInstructorCallId(responseJson: any, errorResponse: any) {
        if (responseJson && responseJson.data) {
            this.setState({ findInstructors: responseJson.data })
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    handleGetProfileCallId(responseJson: any, errorResponse: any) {
        if (responseJson && responseJson.data) {
            this.setState({ profile: responseJson.data })
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    setLocation = (value: string) => {
        this.setState({ location: value });
    }

    setPrice = (value: number) => {
        this.setState({ price: value });
    }

    setRate = (value: number) => {
        this.setState({rate: value})
    }

    getFavoriteInstructors = (token: string, filter?: any) => {
        let query = this.state.searchText;

        if (filter) {
            query += `&${filter}`
        }

        const header = {
            "Content-Type": configJSON.getFavoriteInstructorsAPiContentType,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );


        this.apiGetFavoriteInstructorCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getFavoriteInstructorsAPiEndPoint}?query=${query}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getFavoriteInstructorsAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    viewAllFavoriteInstructors = () => {
        this.props.navigation.navigate("FavoriteInstructors")
    }

    viewAllNewInstructors = () => {
        this.props.navigation.navigate("NewInstructors")
    }

    getNewInstructors = (token: string, filter?: any) => {
        let query = this.state.searchText;

        if (filter) {
            query += `&${filter}`
        }

        const header = {
            "Content-Type": configJSON.getNewInstructorsAPiContentType,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetNewInstructorCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getNewInstructorsAPiEndPoint}?query=${query}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getNewInstructorsAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getFindInstructors = (token: string, query?: string, filter?: any) => {
        let queryFilter = this.state.searchText;

        if (filter) {
            queryFilter += `&${filter}`
        }

        const header = {
            "Content-Type": configJSON.getFindAInstructorsAPiContentType,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );


        this.apiGetFindInstructorCallId = requestMessage.messageId;

        const queryType = query ? `?type=${query}&query=${queryFilter}` : `?query=${queryFilter}`;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getFindAInstructorsAPiEndPoint}${queryType}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getFindAInstructorsAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getProfile = (token: string) => {
        const header = {
            "Content-Type": configJSON.getProfileAPiContentType,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );


        this.apiGetProfileCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getProfileAPiEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getProfileAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    handleDropdownChange = (event: any) => {
        this.setState({ speciality: event.target.value });
    };

    handleProfileClick = (user: any) => () => {
        this.props.navigation.navigate("CoachProfile", { id: user.id })
    }

    onDrawerClose = () => {
        this.setState({isFilterVisible: false})
    }

    onDrawerOpen = () => {
        this.setState({isFilterVisible: true})
    }

    onDrawerFavClose = () => {
        this.setState({isFavoriteFilterOpen: false})
    }

    onDrawerFavOpen = () => {
        this.setState({isFavoriteFilterOpen: true})
    }

    onDrawerNewClose = () => {
        this.setState({isNewFilterOpen: false})
    }

    onDrawerNewOpen = () => {
        this.setState({isNewFilterOpen: true})
    }

    onFilter =  async() => {
        const token = await getStorageData('authToken');
        if (token) {
            const filter: any = {
                ...(this.state.price && { price: `$${this.state.price}` }),
                ...(this.state.rate && { rating: this.state.rate }),
                ...(this.state.location && { location: this.state.location }),
            };
            const queryParams = new URLSearchParams(filter).toString().replace("%24","$");
            this.getNewInstructors(token, queryParams);
            this.getFavoriteInstructors(token, queryParams);
            this.getFindInstructors(token, this.state.speciality, queryParams);
            this.setState({ isFilterVisible: false })
        }
    }

    onFavFilter =  async() => {
        const token = await getStorageData('authToken');
        if (token) {
            const filter: any = {
                ...(this.state.price && { price: `$${this.state.price}` }),
                ...(this.state.rate && { rating: this.state.rate }),
                ...(this.state.location && { location: this.state.location }),
            };
            const queryParams = new URLSearchParams(filter).toString().replace("%24","$");
            this.getFavoriteInstructors(token, queryParams);
            this.setState({ isFavoriteFilterOpen: false })
        }
    }
    

    onNewFilter =  async() => {
        const token = await getStorageData('authToken');
        if (token) {
            const filter: any = {
                ...(this.state.price && { price: `$${this.state.price}` }),
                ...(this.state.rate && { rating: this.state.rate }),
                ...(this.state.location && { location: this.state.location }),
            };
            const queryParams = new URLSearchParams(filter).toString().replace("%24","$");
            this.getNewInstructors(token, queryParams);
            this.setState({ isNewFilterOpen: false })
        }
    }

    onResetFilter = async () => {
        this.setState({ 
            rate: undefined, 
            price: 0,
            location: "",
            isFilterVisible: false,
            isNewFilterOpen: false,
            isFavoriteFilterOpen: false,
        });
        const token = await getStorageData('authToken');
        if (token) {
            this.getNewInstructors(token);
            this.getFavoriteInstructors(token);
            this.getFindInstructors(token, this.state.speciality);
        }
    }
}
