import React from "react";

import {
  Box,
  Typography,
  Grid,
  CardContent,

  // Customizable Area Start
  Button,
  Drawer,
  Checkbox,
  // Customizable Area End
} from "@material-ui/core";
import { Products } from "./InventoryTypes";
// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import HomeLayout from "../../../components/src/HomeLayout";
import { doneIcon, filterIcon, inProgressIcon, pendingIcon, searchIcon } from "./assets";
import moment from "moment";
import Select from "react-select";
import { customDropdownIcon } from "../../../blocks/customisableuserprofiles/src/assets";
// Customizable Area End

import InventoryManagementController, {
  Props,
  configJSON,
} from "./InventoryManagementController";

export default class InventoryManagement extends InventoryManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <HomeLayout onChangeProfile={this.onChangeProfile} profile={this.state.profile} navigation={this.props.navigation} currentPath="MyLessons">
        <PageStyle>
          <TopViewableComponent>
            <Typography variant="h1">
              My Lessons
            </Typography>
            <div className="sideBarContainer">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  bgcolor: '#F7F8F9',
                  border: "1px solid #E8ECF4",
                  borderRadius: '6px',
                  paddingX: '10px',
                  paddingY: '5px',
                }}
              >
                <img src={searchIcon} style={{ width: '16px', height: '18px', marginRight: '10px' }} />
                <input
                  className="searchInput"
                  data-test-id={"searchInput"}
                  placeholder="Search"
                  value={this.state.searchText}
                  onChange={(event) => this.setState({searchText: event.target.value})}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  border: "1px solid #2F7735",
                  borderRadius: '6px',
                  paddingX: '10px',
                  paddingY: '5px',
                  width: "min-content",
                }}
                style={{ cursor: "pointer" }}
                onClick={() => this.onToggleFilter()}
                data-test-id="filterBtn"
                >
                <img src={filterIcon} style={{ width: '18px', height: '18px' }} />
                <Typography className="filterText" variant="subtitle1" style={{ color: "#2F7735" }}>Filter</Typography>
              </Box>
            </div>
          </TopViewableComponent>

          <Grid className="container" container spacing={3} style={{ marginTop: "10px", paddingBottom: "10px" }}>
            {!this.state.isLessonLoading && this.state.myLessons?.length === 0 && <Typography>No Lesson Found!</Typography>}
            {this.state.myLessons?.map((lesson: any, index) => (
              <Grid key={index} item xs={12} sm={12} md={6} style={{ position: "relative", cursor: "pointer" }} onClick={() => {this.props.navigation.navigate("LessonDetails", {id: lesson.id})}}>
                <div className="itemContainer">
                <img src={lesson.attributes.thumbnail ?? this.state.profile.attributes.role === "instructor" ? lesson.attributes.player_profile.attributes.photo : lesson.attributes.instructor_profile.attributes.photo} className="itemBanner" />                 
                <div className="itemInfo">
                    <div className="itemInfoLeft">
                      <Typography style={{ color: "#141414", fontSize: "18px", fontWeight: 700 }}>
                        {this.state.profile.attributes.role === "instructor" ? `${lesson.attributes.player_profile.attributes.first_name} ${lesson.attributes.player_profile.attributes.last_name}` : `${lesson.attributes.instructor_profile.attributes.first_name} ${lesson.attributes.instructor_profile.attributes.last_name}`}
                      </Typography>
                      <Typography style={{ color: "#333333", fontSize: "12px", fontWeight: 600, textTransform: "capitalize" }}>
                        {lesson.attributes.person_type}
                      </Typography>
                      <Typography style={{ color: "#8391A1", fontSize: "12px", fontWeight: 500 }}>
                        {this.convertToUserTime(moment(lesson.attributes.created).format('DD-MMM-YYYY hh:mm A'))}
                      </Typography>
                    </div>
                    <div className="itemInfoRight">
                      <img style={{height: "23px"}} src={(mappingBadge as any)[lesson.attributes.lesson_status]} />
                      {lesson.attributes.lesson_status === "in_progress" && <Button onClick={this.onSend(lesson?.attributes?.chat_id)} style={{backgroundColor: "#2F7735", color: "white", borderRadius: "4px", fontSize: "12px", width: "70px", height: "28px", textTransform: "none"}} title="Send">Send</Button>}
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
          <DrawerStyle
          anchor="right"
          open={this.state.isFilterVisible}
          onClose={this.onDrawerClose}
          PaperProps={{ style: { background: 'white' }, className: "drawerContainer" }}
        >
          <Box sx={{ mb: 2, display: "flex", flex:1 }}>
            <ul
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                listStyleType: "none",
                justifyContent: "center",
                paddingInline: "35px",
                gap: "20px",
                fontFamily: "Urbanist, sans-serif",
              }}
            >
              <Typography variant="h2" style={{fontWeight: 700, fontSize: "32px", fontFamily: "inherit", marginTop: "25px"}}>
                Filter
              </Typography>
              <Typography variant="h3" style={{fontWeight: 700, fontSize: "18px", fontFamily: "inherit"}}>
                Lesson Status
              </Typography>
              <div style={{display: "flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
                <Checkbox
                  data-test-id="doneCheckbox"
                  onClick={() =>
                    this.onSetFilterLessonStatus("done")
                  }
                  checked={this.state.filter.lesson_status === "done"}
                    style={{ padding: '0px', color: '#2F7735', }}
                />
                <Typography variant="h3" style={{fontWeight: 500, fontSize: "16px", color: "#333333", fontFamily: "inherit"}}>
                 Done
              </Typography>
              </div>
              <div style={{display: "flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
                <Checkbox
                  data-test-id="inProgressCheckbox"
                  onClick={() =>
                    this.onSetFilterLessonStatus("in_progress")
                  }
                  checked={this.state.filter.lesson_status === "in_progress"}
                  style={{ padding: '0px', color: '#2F7735', }}
                />
                <Typography variant="h3" style={{fontWeight: 500, fontSize: "16px", color: "#333333", fontFamily: "inherit"}}>
                 In-Progress
              </Typography>
              </div>
              <div style={{display: "flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
                <Checkbox
                  data-test-id="pendingCheckbox"
                  onClick={() =>
                    this.onSetFilterLessonStatus("pending")
                  }
                  checked={this.state.filter.lesson_status === "pending"}
                    style={{ padding: '0px', color: '#2F7735', }}
                />
                <Typography variant="h3" style={{fontWeight: 500, fontSize: "16px", color: "#333333", fontFamily: "inherit"}}>
                 Pending
              </Typography>
              </div>
                <div className="inputField" style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '96%' }}>
                  <label className="labelStyle" style={{marginBottom: "5px", marginTop: "5px", fontWeight: 700, fontSize: "15px", color: "#333333"}}>
                    Sort by
                  </label>
                  <Select
                    style={{
                      width: '96%', height: '56px', borderRadius: '8px',
                      backgroundColor: '#F7F8F9',
                    }}
                    options={[{label: "None", value: ""}, {label: "Most Recent", value: "most_recent"}, {label: "Least Recent", value: "least_recent"}, {label: "Alphabetical A-Z", value: "alphabet"}]}
                    placeholder="Select"
                    data-test-id='handleChangeSortBy'
                    onChange={(item) => { this.onsetSortBy(item) }}
                    value={[{label: "None", value: ""}, {label: "Most Recent", value: "most_recent"}, {label: "Least Recent", value: "least_recent"}, {label: "Alphabetical A-Z", value: "alphabet"}].find(item => item.value === this.state.filter.sortBy)}
                    data-testid="sortBy"
                    name="sortBy"
                    styles={customStyles}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => (
                        <img
                          src={customDropdownIcon}
                          alt="Dropdown"
                          style={{ width: "25px", height: "25px" }}
                        />
                      ),
                    }}
                  />
                </div>
                <div className="inputField" style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '96%' }}>
                  <label className="labelStyle" style={{marginBottom: "5px", marginTop: "5px", fontWeight: 700, fontSize: "15px", color: "#333333"}}>
                    Student Type
                  </label>
                  <Select
                    style={{
                      width: '96%', height: '56px', borderRadius: '8px',
                      backgroundColor: '#F7F8F9',
                      marginTop: "5px"
                    }}
                    options={[{
                      label: "None",
                      value: ""
                    },
                    {
                      label: "Single",
                      value: "single"
                    },
                    {
                      label: "In-Person",
                      value: "in_person_student"
                    },
                    {
                      label: "All Students",
                      value: "all_students"
                    }
                    ]}
                    placeholder="Select"
                    data-test-id='handleChangeStudentType'
                    onChange={(item) => { this.onsetFilterStudentType(item) }}
                    value={[{
                      label: "None",
                      value: ""
                    },
                    {
                      label: "Single",
                      value: "single"
                    },
                    {
                      label: "In-Person",
                      value: "in_person_student"
                    },
                    {
                      label: "All Students",
                      value: "all_students"
                    }
                    ].find(item => item.value === this.state.filter.person_type)}
                    name="studentType"
                    styles={customStyles}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => (
                        <img
                          src={customDropdownIcon}
                          alt="Dropdown"
                          style={{ width: "25px", height: "25px" }}
                        />
                      ),
                    }}
                  />
                </div>
                <div style={{width: "100%", display: "flex", flex:1, paddingBottom: "20px", gap: "10px", flexDirection: "row", alignItems: "flex-end", justifyContent:"space-between"}}>
                  <Button data-test-id="clearBtn" className="clearBtn" onClick={() => this.onResetFilter()}>Clear</Button>
                  <Button data-test-id="submitBtn" className="submitBtn" onClick={() => this.onFilter()}>Filter</Button>
                </div>
            </ul>
          </Box>
        </DrawerStyle>
        </PageStyle>
      </HomeLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const customStyles: any = {
  control: (provided: any) => ({
    ...provided,
    width: '100%',
    border: "1px solid #E8ECF4",
    borderRadius: '8px',
    height: '56px',
    outline: "none",
    backgroundColor: "#F7F8F9",
    padding: "10px",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#333333',
  }),
  indicatorsSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: "red",
  }),
};

const TopViewableComponent = styled("div")({
  display: 'flex',
  flexDirection: 'row',
  paddingInline: '100px',
  alignItems: 'center',
  marginBlock: "20px",
  justifyContent: 'space-between',

  "@media (max-width: 960px)": {
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    paddingInline: '30px',
  },

  "& h1": {
    fontSize: "24px",
    fontWeight: 700,
  },
})

const DrawerStyle = styled(Drawer)({
  "& *": {
    fontFamily: "Urbanist, sans-serif",
  },
  "& .drawerContainer": {
    width: "30%",
    display: "flex",
    flex: 1,  

    "@media (max-width: 1280px)": {
      width: '50%',
    },
    "@media (max-width: 600px)": {
      width: '80%',
    },
    "& .clearBtn": {
      display: "flex",
      flex: 1,
      height: "56px",
      border: "1px solid #2F7735",
      color: "#2F7735",
      borderRadius: "8px",
      fontWeight: 600,
      textTransform: "capitalize",
      fontSize: "15px",
    },
    "& .submitBtn": {
      fontSize: "15px",
      textTransform: "capitalize",
      display: "flex",
      flex: 1,
      height: "56px",
      border: "1px solid #2F7735",
      color: "white",
      borderRadius: "8px",
      backgroundColor: "#2F7735",
      fontWeight: 600
    },
  },
})

const PageStyle = styled("div")({
  display: "flex",
  flexDirection: "column",

  "& *": {
    fontFamily: "Urbanist, sans-serif",
  },

  "& .itemContainer": {
    backgroundColor: "#F7F8F9",
    height: "250px",
    borderRadius: "7px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #E8ECF4"
  },

  "& .itemBanner": {
    width: "100%",
    height: "150px",
    objectFit: "cover",
    backgroundColor: "gray"
  },

  "& .itemInfo": {
    padding: "15px 20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,

    "& .itemInfoLeft": {
      display: "flex",
      justifyContent: "space-evenly",
      flexDirection: "column",
      height: "100%"
    },
    "& .itemInfoRight": {
      height: "100%",
      marginTop: "8px",
      display: "flex",
      flexDirection: "column",
      alignItems:"flex-end",
      justifyContent: "space-between",
    }
  },

  "& .container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    width: "100%",
    paddingInline: '100px',
    
    "@media (max-width: 960px)": {
      paddingInline: '50px',
    },

    "@media (max-width: 600px)": {
      paddingInline: '30px',
    },
  },

  "& .sideBarContainer": {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px'
  },

  "& .filterText": {
    marginLeft: "10px",

    "@media (max-width: 600px)": {
      display: "none"
    },
  },

  "& .searchInput": {
    border: 'none',
    color: "#8391A1",
    backgroundColor: "#F7F8F9",
    width: "250px",

    "@media (max-width: 650px)": {
      width: "120px",
    },

    "@media (max-width: 400px)": {
      width: "100px",
    },

    "&:focus": {
      outline: 'none',
      border: 'none',
  },
  }
})

const mappingBadge = {
  "in_progress": inProgressIcon,
  "done": doneIcon,
  "pending": pendingIcon,
}

// Customizable Area End
