import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import UserProfileController, {
  Props,
} from "./UserProfileController.web";
import HomeLayout from "../../../components/src/HomeLayout";
import { Button, styled } from "@material-ui/core";
import { defaultAvatar, icTrash, icVisa } from "./assets";
// Customizable Area End

// Customizable Area Start

const PageStyle = styled("div")({
  marginTop: "30px",
  paddingInline: "100px",

  "@media (max-width: 960px)": {
    marginTop: "30px",
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    marginTop: "30px",
    paddingInline: '30px',
  },

  "& .userPhoto": {
    width: "96px",
    height: "96px",
    borderRadius: "50%",
  },
  "& .userTextContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
  },
  "& .userTitle": {
    fontSize: "24px",
    fontWeight: 600,
  },
  "& .userSubtitle": {
    fontSize: "15px",
    fontWeight: 500,
    color: "#8391A1"
  },

  "& .userDescription": {
    fontWeight: 500,
    fontSize: "15px",
    maxWidth: "600px",
    lineHeight: "19.5px",
    marginTop: "20px",

    "@media(max-width: 860px)": {
      maxWidth: "unset",
    }
  },

  "& .paymentInfoText": {
    fontWeight: 700,
    fontSize: "16px",
  },

  "& .card": {
    width: "450px",
    height: "60px",
    borderRadius: "8px",
    backgroundColor: "#F7F8F9",
    border: "1px solid #E8ECF4",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",

    "@media(max-width: 860px)": {
      maxWidth: "100%",
    }
  },

  "& .editProfileBtn": {
    height: '56px',
    width: "160px",
    background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
    color: '#FFFFFF',
    fontSize: "15px",
    borderRadius: '8px',
    fontWeight: 600,
    marginTop: "20px",
    marginBottom: "20px",
    textTransform: "unset",

    "@media(max-width: 860px)": {
      width: "145px",
    }
  }
})
// Customizable Area End


export default class UserProfile extends UserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start

      <HomeLayout navigation={this.props.navigation} profile={this.state.profile}>
        <PageStyle>
          <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "24px", fontWeight: "700", padding: '10px 0 20px 0' }}>Profile</Typography>
          <div>
            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              <img className="userPhoto" src={this.state.profile?.attributes?.photo ?? defaultAvatar} />
              <div className="userTextContainer">
                <Typography variant="h3" className="userTitle">
                  {`${this.state.profile?.attributes?.first_name} ${this.state.profile?.attributes?.last_name}`}
                </Typography>
                <Typography variant="h4" className="userSubtitle">{this.state.profile?.attributes?.email}</Typography>
                <Typography variant="h4" className="userSubtitle">{this.state.profile?.attributes?.handicap}</Typography>
              </div>
            </div>
            <Typography variant="body1" className="userDescription">
              {this.state.profile?.attributes?.bio}
            </Typography>
            <Typography variant="h4" className="paymentInfoText" style={{ marginTop: "20px" }}>Payment Information</Typography>
            <div className="card">
              <div style={{ marginInline: "14px", backgroundColor: "white", width: "36px", height: "36px", borderRadius: "4px", border: "1px solid #E8ECF4", display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                <img src={icVisa} style={{ width: "32px", height: "auto" }} />
              </div>
              <div style={{ display: "flex", flex: 1, flexDirection: "column", gap: "2px" }}>
                <Typography variant="h4" className="paymentInfoText" style={{ fontSize: "18px" }}>Visa ending with 7890</Typography>
                <Typography variant="h4" className="userSubtitle" style={{ fontSize: "12px" }}>Exp Date 12/29</Typography>
              </div>
              <img src={icTrash} style={{ width: "24px", height: "auto", marginRight: "20px" }} />
            </div>
            <Button data-test-id='edit-profile-btn' className="editProfileBtn" onClick={this.onEditProfile}>Edit Profile</Button>
          </div>
        </PageStyle>
      </HomeLayout>
      // Customizable Area End
    )
  }
}
