import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    profile: any;
    coachProfile: any;
    coachId?: string;
    isFavourite: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CoachProfileController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiGetProfileApiCallId: any;
    apiGetCoachProfileApiCallId: any;
    apiAddToFavouriteCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            coachId: this.props.navigation.getParam("id"),
            coachProfile: undefined,
            profile: undefined,
            isFavourite: false,
        };


        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            this.handleAPIResponseMessage(message);
        }
        // Customizable Area End
    }

    // Customizable Area Start

    handleAPIResponseMessage(message: Message) {
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (apiRequestCallId === this.apiGetCoachProfileApiCallId) {
            this.handleGetCoachProfileCallId(responseJson, errorResponse);
        }

        if (apiRequestCallId === this.apiGetProfileApiCallId) {
            this.handleGetMyProfileCallId(responseJson, errorResponse);
        }
    }

    handleGetCoachProfileCallId(responseJson: any, errorResponse: any) {
        if (responseJson && responseJson.data) {
            this.setState({ coachProfile: responseJson.data, isFavourite: responseJson.data.attributes.favourite })
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    handleGetMyProfileCallId(responseJson: any, errorResponse: any) {
        if (responseJson && responseJson.data) {
            this.setState({ profile: responseJson.data })
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }


    async componentDidMount() {
        try {
            const token = await getStorageData('authToken');
            if (token) {
                this.getCoachProfile(token);
                this.getProfile(token);
            } else this.props.navigation.navigate("EmailAccountLogin");
        } catch { }
    }

    onBuyNow = () => {
        this.props.navigation.navigate("Scheduling", {id: this.props.navigation.getParam("id") })
    }

    onFavourite = async () => {
        const token = await getStorageData('authToken');

        const header = {
            "Content-Type": configJSON.contentTypeApiAddToFavourite,
            token: token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiAddToFavouriteCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiAddToFavourite
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
           JSON.stringify({
                "data": {
                    "instructor_id": this.props.navigation.getParam("id"),
                    "favourite": !this.state.isFavourite
                }
            })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.endPointApiAddToFavourite}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        if (!this.state.isFavourite) {
            toast.success("Instructor marked as favorite");
        }
        
        this.setState({isFavourite: !this.state.isFavourite})
    }

    getProfile = (token: string) => {
        const header = {
            "Content-Type": configJSON.getProfileAPiContentType,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetProfileApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getProfileAPiMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getProfileAPiEndPoint}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getCoachProfile = (token: string) => {
        const header = {
            "Content-Type": configJSON.contentTypeApiGetCoachProfile,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetCoachProfileApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetCoachProfile
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.endPointApiGetCoachProfile}?id=${this.state.coachId}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End
}
