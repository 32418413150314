import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment-timezone";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    profile: any;
    lessonInfo: any;
    isRateOpen: boolean;
    rate: number;
    feedback: string;
    rateError?: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start

// Customizable Area End

export default class InventoryManagementDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    lessonId = ""
    getShowLessonCallId = ""
    apiDeleteVideoApiCallId = ""
    apiCreateReviewCallId = ""
    generateStarFunction: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            // Customizable Area Start
            profile: undefined,
            lessonInfo: undefined,
            isRateOpen: false,
            rate: 0,
            feedback: "",
            rateError: undefined,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.lessonId = this.props.navigation.getParam("id");
        // Customizable Area End
    }

    async componentDidMount(): Promise<void> {
        const token = await getStorageData('authToken');
        if (token) {
            this.getShowLesson(token);
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors) {
                if (apiRequestCallId === this.getShowLessonCallId) {
                    this.setState({lessonInfo: responseJson.data});
                } else if (apiRequestCallId === this.apiDeleteVideoApiCallId) {
                    toast.success("Delete Video Successfully!");
                    const token = await getStorageData('authToken');
                    this.getShowLesson(token);
                } else if (apiRequestCallId === this.apiCreateReviewCallId) {
                    if (responseJson.data?.reviewed) {
                        this.setState({rateError: responseJson.data?.message})
                    } else {
                        toast.success("Rate Successfully!");
                        this.onRateReset();
                    }
                }
            } else {
                alert(JSON.stringify(responseJson?.errors[0][0]));
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start

    onRateReset = () => {
        this.setState({rate: 0, feedback: "", isRateOpen: false, rateError: undefined});
    }

    onSetRate = (value: number) => () => {
        this.setState({rate: value})
    }

    onChangeProfile = (value: any) => {
        this.setState({ profile: value })
    }

    onRemoveVideo = (id: string) => async () => {
        const token = await getStorageData('authToken');

        const header = {
            "Content-Type": "application/json",
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiDeleteVideoApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.endPointApiDeleteVideo}/${id}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    convertToUserTime = (time: any) => {
        const timezone = moment.tz.guess();
        const localAvailibilityDate = moment.tz(time, "DD-MMM-YYYY hh:mm A", "UTC").tz(timezone).format("DD-MMM-YYYY hh:mm A");
        return localAvailibilityDate;
      };

    getShowLesson = (token: string) => {
        const header = {
          "Content-Type": "application/json",
          token: token
        };
    
        const request = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getShowLessonCallId = request.messageId;
    
        request.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.showLessonEndPoint}/${this.props.navigation.getParam("id")}`
        );
    
        request.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        request.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
    
        runEngine.sendMessage(request.id, request);
      };

    createReview = async () => {
        const token = await getStorageData('authToken');

        const header = {
          "Content-Type": "application/json",
          token: token
        };
    
        const request = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.apiCreateReviewCallId = request.messageId;
    
        request.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.createReviewEndPoint}`
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({
                data: {
                    "attributes": {
                        "rating": this.state.rate,
                        "feedback": this.state.feedback,
                        "account_id": this.state.lessonInfo?.attributes?.instructor_profile?.attributes.account_id,
                        "anonymous": false
                    }
                }
            })
        );
    
        request.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        request.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
    
        runEngine.sendMessage(request.id, request);
    };

    onAddOrignalVideos = () => {
        this.props.navigation.navigate("AddOriginalVideos", {lessonId: this.lessonId})
    }

    onAddAnalysisVideos = () => {
        this.props.navigation.navigate("AddAnalysisVideos", {lessonId: this.lessonId})
    }

    onAddAfterVideosTipsDrills =  () => {
        this.props.navigation.navigate("AddAfterVideosTipsDrill", {lessonId: this.lessonId})
    }

    onDrawerClose = () => {
        this.setState({isRateOpen: false})
    }

    onFeedbackChange = (event: any) => {
        this.setState({ feedback: event?.target?.value });
      }
    

    // Customizable Area End
}
